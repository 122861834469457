
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import Partners from '@/apis/UserPartners'

@Component({
  name: 'UserPartners',
  components: {
  }
})

export default class UserPartners extends Mixins(TableMixin) {
  simpleTable = false
  dealerships = []
  dealershipsOnModeration = []
  leasingCompanies = []
  networks = []
  partnersList = {
    headers: [
      {
        text: 'Категория',
        align: 'start',
        value: 'category'
      },
      {
        text: 'Наименование',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Расположение',
        align: 'start',
        value: 'address'
      },
      {
        text: 'Тариф',
        align: 'start',
        value: 'tariff'
      },
      {
        text: 'Статус',
        align: 'start',
        value: 'status'
      },
      {
        text: 'Редактировать',
        align: 'start',
        value: 'edit'
      }
    ],
    items: []
  }

  isLoading = true

  created() {
    this.getPartners()
  }

  getPartners() {
    Partners.fetchPartners()
      .then(({ data }) => {
        this.dealerships = data.dealerships
        this.dealershipsOnModeration = data.dealershipsOnModeration
        this.leasingCompanies = data.leasingCompanies
        this.networks = data.networks
        this.getAll()
        this.isLoading = false
      })
  }

  getDealerships() {
    this.partnersList.items = []
    this.partnersList.items = this.dealerships
  }

  getDealershipsOnModeration() {
    this.partnersList.items = []
    this.partnersList.items = this.dealershipsOnModeration
  }

  getLeasingCompanies() {
    this.partnersList.items = []
    this.partnersList.items = this.leasingCompanies
  }

  getNetworks() {
    this.partnersList.items = []
    this.partnersList.items = this.networks
  }

  getAll() {
    this.partnersList.items = []
    this.partnersList.items.push(...this.dealerships)
    this.partnersList.items.push(...this.leasingCompanies)
    this.partnersList.items.push(...this.networks)
  }

  get search() {
    return this.$store.state.common.searchAdminPartners
  }

  set search(item) {
    this.$store.state.common.searchAdminPartners = item
  }

  countPartners(number: number): string {
    return stringUtils.pluralForm(number, ['партнер', 'партнера', 'партнеров'])
  }
}
